<template>
  <div>
    <header class="flex items-center justify-between p-6 border-b">
      <div>
        <h1 class="text-3xl font-semibold">
          Тарифы <i v-if="loading" class="el-icon-loading"></i>
        </h1>
        <div class="mt-1 text-sm font-light text-gray-700">
          {{ tarifList.length }} шт
        </div>
      </div>
      <div class="flex items-center">
        <el-select
          class="mr-2"
          v-model="filters.state"
          @change="getTarifList"
          style="width: 200px"
        >
          <el-option :value="-1" label="Удаленные"></el-option>
          <el-option :value="0" label="Неактивные"></el-option>
          <el-option :value="1" label="Активные"></el-option>
        </el-select>
        <el-button @click="openForm" type="primary" plain>Добавить</el-button>
      </div>
    </header>
    <div class="px-5 py-5">
      <el-table :data="tarifList" @row-click="goToTariff" border>
        <el-table-column prop="title" label="Название тарифа"></el-table-column>
        <el-table-column prop="tariff" label="Тариф"></el-table-column>
        <el-table-column prop="type" label="Тип пользователя">
          <template v-slot="scope">
            {{ type[scope.row.type] }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      v-if="total"
      class="w-full flex items-center justify-center mt-3"
      background
      layout="pager"
      :total="total"
      :default-current-page="filters.page"
      :current-page="filters.page"
      @update:current-page="pageChange"
      :page-size="10"
    />
    <router-view @backWithUpdate="backWithUpdate" @getList="getTarifList" />
  </div>
</template>

<script>
import { getTarifList } from "@/api/tarif";

export default {
  name: "tarif",
  data() {
    return {
      tarifList: [],
      loading: false,
      total: null,
      filters: {
        page: 1,
        limit: 10,
        state: 1,
      },
      type: {
        1: "Annontator",
        2: "Verifier",
      },
    };
  },
  async mounted() {
    await this.getTarifList();
  },
  methods: {
    async getTarifList() {
      let params = {};
      if (this.filters.page > 1) {
        params.page = this.filters.page;
      }
      params.limit = this.filters.limit;
      params.state = this.filters.state;
      let data = await getTarifList(params);
      this.tarifList = data.data;
      this.total = data.page.total_items;
      console.log(this.total_items);
      // this.filters.limit = data.page.limit;

      console.log(data);
    },
    pageChange(page) {
      this.filters.page = page;
      this.getTarifList();
    },
    goToTariff(row) {
      console.log(row._id);
      this.$router.push({ name: "tariff-item", params: { tarif_id: row._id } });
    },
    async openForm() {
      // const { action, form } = await this.$refs["createTarifForm"].open();
      // if (action) {
      //   await createTarif(form);
      //   await this.getTarifList();
      // }
      this.$router.push({ name: "tariff-item", params: { tarif_id: "new" } });
    },
    backWithUpdate(val) {
      this.filters.state = val;
      this.getTarifList();
    },
  },
};
</script>

<style scoped></style>
