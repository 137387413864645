import "core-js/modules/es.array.push.js";
import { getTarifList } from "@/api/tarif";
export default {
  name: "tarif",
  data() {
    return {
      tarifList: [],
      loading: false,
      total: null,
      filters: {
        page: 1,
        limit: 10,
        state: 1
      },
      type: {
        1: "Annontator",
        2: "Verifier"
      }
    };
  },
  async mounted() {
    await this.getTarifList();
  },
  methods: {
    async getTarifList() {
      let params = {};
      if (this.filters.page > 1) {
        params.page = this.filters.page;
      }
      params.limit = this.filters.limit;
      params.state = this.filters.state;
      let data = await getTarifList(params);
      this.tarifList = data.data;
      this.total = data.page.total_items;
      console.log(this.total_items);
      // this.filters.limit = data.page.limit;

      console.log(data);
    },
    pageChange(page) {
      this.filters.page = page;
      this.getTarifList();
    },
    goToTariff(row) {
      console.log(row._id);
      this.$router.push({
        name: "tariff-item",
        params: {
          tarif_id: row._id
        }
      });
    },
    async openForm() {
      // const { action, form } = await this.$refs["createTarifForm"].open();
      // if (action) {
      //   await createTarif(form);
      //   await this.getTarifList();
      // }
      this.$router.push({
        name: "tariff-item",
        params: {
          tarif_id: "new"
        }
      });
    },
    backWithUpdate(val) {
      this.filters.state = val;
      this.getTarifList();
    }
  }
};